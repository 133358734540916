import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchFeeds = createAsyncThunk(
  "feed/fetchFeeds",
  async ({ page, limit }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/community/getfeed-Foryou`,
        {
          params: { page, limit },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Server Error");
    }
  }
);

export const fetchCurrentPoll = createAsyncThunk(
  "feed/fetchCurrentPoll",
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/community/getOpinonPoll`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          }
        }
      );

      console.log(response.data);
      return response.data;
    } catch (error) {
     console.log(error);
    }
  }
);

export const fetchFeedsByTagOrSymbol = createAsyncThunk(
  "feeds/fetchByTagOrSymbol",
  async (searchKey, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/community/feed/getFeedsByTagOrSymbol`,
        {
          params: { searchKey },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          }
        },
      );
      console.log(response.data);
      return response.data; 
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Failed to fetch feeds");
    }
  }
);

export const fetchFeedByNewsTags=createAsyncThunk(
  "feeds/fetchFeedByNewsTags",
  async (newsId, { rejectWithValue }) => {
    try {
      console.log(newsId);
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/community/feed/getFeedByNewsTags`,
        {
          params: { newsId },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          }
        },
      );
      console.log(response.data);
      return response.data; 
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Failed to fetch feeds");
    }
  }
);

export const createFeedPost = createAsyncThunk(
  "feed/createFeedPost",
  async (postData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/community/add-feed`,
        postData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Server Error");
    }
  }
);

export const likeFeedPost = createAsyncThunk(
  "feed/likeFeedPost",
  async ({ feedId, userId }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/community/feed/${feedId}/like/${userId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Server Error");
    }
  }
);

export const unLikeFeedPost = createAsyncThunk(
  "feed/unLikeFeedPost",
  async ({ feedId, userId }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/community/feed/${feedId}/unLike/${userId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Server Error");
    }
  }
);

export const voteOnPoll = createAsyncThunk(
  "feed/voteOnPoll",
  async ({ feedId, optionIndex, userId }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/community/feed-poll`,
        {
          feedId,
          optionIndex,
          userId,
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Server Error");
    }
  }
);

export const addComment = createAsyncThunk(
  "feed/addComment",
  async (commentData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/community/feed/addComment`,
        commentData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Server Error");
    }
  }
);

export const addReply = createAsyncThunk(
  "feed/addReply",
  async (replyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/community/comment/addReply`,
        replyData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Server Error");
    }
  }
);

export const fetchComments = createAsyncThunk(
  "feed/fetchComments",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/community/feed/getComment/${data.id}/${data.type}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Server Error");
    }
  }
);

export const likeComment = createAsyncThunk(
  "feed/likeComment",
  async ({ commentId, userId }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/community/feedComment/${commentId}/like/${userId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Server Error");
    }
  }
);

export const unLikeComment= createAsyncThunk(
  "feed/unLikeComment",
  async ({ commentId, userId }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/community/feedComment/${commentId}/unLike/${userId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Server Error");
    }
  }
);

const initialState = {
  feeds: [],
  coinFeeds:[],
  comments: [],
  replies:[],
  feedNewsTag:[],
  opinionPoll:[],
  opinionStatus:"idle",
  feedNewsTagStatus:"idle",
  coinFeedStatus:'idle',
  pagination: {
    totalFeeds: 0,
    currentPage: 1,
    totalPages: 0,
  },
  status: "idle",
  error: null,
};

const feedSlice = createSlice({
  name: "feed",
  initialState,
  reducers: {
    clearFeeds: (state) => {
      state.feeds = [];
      state.pagination = {};
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeeds.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchFeeds.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.feeds = [...state.feeds, ...action.payload.feeds];
        state.pagination = action.payload.pagination;
      })
      .addCase(fetchFeeds.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });

      builder
      .addCase(fetchFeedsByTagOrSymbol.pending, (state) => {
        state.coinFeedStatus = "loading";
      })
      .addCase(fetchFeedsByTagOrSymbol.fulfilled, (state, action) => {
        state.coinFeedStatus = "succeeded";
        state.coinFeeds = action.payload.feeds;
      })
      .addCase(fetchFeedsByTagOrSymbol.rejected, (state, action) => {
        state.coinFeedStatus = "failed";
        state.error = action.payload;
      });

      builder
      .addCase(fetchFeedByNewsTags.pending, (state) => {
        state.feedNewsTagStatus = "loading";
      })
      .addCase(fetchFeedByNewsTags.fulfilled, (state, action) => {
        state.feedNewsTagStatus = "succeeded";
        state.feedNewsTag = action.payload.feeds;
      })
      .addCase(fetchFeedByNewsTags.rejected, (state, action) => {
        state.feedNewsTagStatus = "failed";
        state.error = action.payload;
      });

      builder
      .addCase(fetchCurrentPoll.pending, (state) => {
        state.opinionStatus = "loading";
      })
      .addCase(fetchCurrentPoll.fulfilled, (state, action) => {
        state.opinionStatus = "succeeded";
        state.opinionPoll = action.payload?.feeds;
      })
      .addCase(fetchCurrentPoll.rejected, (state, action) => {
        state.opinionStatus = "failed";
        state.error = action.payload;
      });

    builder
      .addCase(createFeedPost.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createFeedPost.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.feeds.unshift(action.payload.data);
      })
      .addCase(createFeedPost.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });

    builder.addCase(likeFeedPost.fulfilled, (state, action) => {
      const updatedFeed = action.payload.feed;
    });

    builder.addCase(unLikeFeedPost.fulfilled, (state, action) => {
      const updatedFeed = action.payload.feed;
    });

    builder.addCase(voteOnPoll.fulfilled, (state, action) => {
      const updatedPoll = action.payload.poll;
      const feedIndex = state.feeds.findIndex(
        (feed) => feed._id === action.meta.arg.feedId
      );
      if (feedIndex !== -1) {
        state.feeds[feedIndex].poll = updatedPoll;
      }
    });

    builder.addCase(addComment.fulfilled, (state, action) => {
      state.comments.push(action.payload);
    });

    builder.addCase(addReply.fulfilled, (state, action) => {
      state.replies.push(action.payload);
    });

    builder.addCase(fetchComments.fulfilled, (state, action) => {
      state.comments = action.payload;
    });
  },
});

export const { clearFeeds } = feedSlice.actions;

export default feedSlice.reducer;
