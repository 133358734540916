import { useEffect, useRef, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { Text } from "@react-three/drei";
import { PerspectiveCamera, Vector3 } from "three";
import {
  MeshWobbleMaterial,
  OrbitControls,
  useGLTF,
  Preload,
} from "@react-three/drei";
import { useDispatch } from "react-redux";
import {
  addEarnCoin,
  fetchCoinCount,
} from "../../store/customer/EarnCoinSlice";


const CoinModel = () => {
  console.log(process.env.REACT_APP_GLTF_URL);
  const { scene } = useGLTF(
    process.env.REACT_APP_GLTF_URL,
    true,
    "https://www.gstatic.com/draco/v1/decoders/"
  );

  console.log(scene);
  return scene ? (
    <primitive object={scene} scale={[0.3, 0.3, 0.3]} />
  ) : (
    <cylinderGeometry args={[0.1, 0.1, 0.05, 20]} />
  );
};

const Sphere = ({ position, release, setRelease, amount }) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const [isDestroyed, setIsDestroyed] = useState(false);
  const [fragments, setFragments] = useState([]);
  const groupRef = useRef();
  const [initialCoinPosition, setIntialCoinPosition] = useState(
    new Vector3(position[0] * 25, position[1] * 25, position[2])
  );
  const [collidPostion, setCollidPosition] = useState(null);
  const [textOpacity, setTextOpacity] = useState(1);
  const [textPosition, setTextPosition] = useState(null);

  const COLLISION_DISTANCE = 0.2;
  let targetPosition = null;

  useFrame(() => {
    if (isDestroyed && groupRef.current) {
      groupRef.current.children.forEach((child, i) => {
        child.position.x += fragments[i].x * 0.02;
        child.position.y += fragments[i].y * 0.02;
        child.position.z += fragments[i].z * 0.02;
      });
    }
  });

  useFrame((state, delta) => {
    if (!release) return; // Skip movement logic if not released

    if (ref.current) {
      // ref.current.rotation.x += delta * 2;
      // ref.current.rotation.z += delta * 2;
      ref.current.rotation.y += delta * 10;
      ref.current.position.y += delta;
      // ref.current.position.z += delta / 2;
    }
  });

  useEffect(() => {
    if (release) {
      const collisionTimeout = setTimeout(() => {
        if (ref.current) {
          setIsDestroyed(true);
          const fragmentPositions = Array.from({ length: 20 }, () => ({
            x: (Math.random() - 0.5) * 2,
            y: (Math.random() - 0.5) * 2,
            z: (Math.random() - 0.5) * 2,
          }));

          setCollidPosition(ref.current.position);
          setFragments(fragmentPositions);
        }
      }, 200);

      let fadeInterval;
      if (textPosition) {
        fadeInterval = setInterval(() => {
          setTextOpacity((prev) => Math.max(prev - 0.05, 0));
          setTextPosition((prev) =>
            prev ? new Vector3(prev.x, prev.y + 0.02, prev.z) : null
          );
        }, 2000);
      }

      const resetTimeout = setTimeout(async () => {
        setRelease(false);
        setIsDestroyed(false);
        setTextOpacity(1);
        setTextPosition(null);
        clearInterval(fadeInterval);
        const action = await dispatch(addEarnCoin(amount));
        if (addEarnCoin.fulfilled.match(action)) {
          dispatch(fetchCoinCount());
        }
      }, 1000);

      return () => {
        clearTimeout(collisionTimeout);
        clearTimeout(resetTimeout);
        clearInterval(fadeInterval);
      };
    }
  }, [release]);

  return (
    <>
      {release &&
        (!isDestroyed ? (
          <mesh>
            <mesh ref={ref} position={initialCoinPosition}>
              <CoinModel />
              <Preload all />
            </mesh>
          </mesh>
        ) : (
          // Render a group of fragments if `isDestroyed` is true
          <group ref={groupRef}>
            {fragments.map((_, index) => (
              <mesh key={index} position={collidPostion}>
                <boxGeometry args={[0.02, 0.02, 0.02]} />
                <meshStandardMaterial
                  color="#DAA520"
                  metalness={0.6}
                  roughness={0.1}
                />
              </mesh>
            ))}

            {textPosition && (
              <Text
                position={textPosition}
                fontSize={1.5}
                color="white"
                opacity={textOpacity}
                anchorX="center"
                anchorY="middle"
              >
                +{amount}
              </Text>
            )}
          </group>
        ))}
    </>
  );
};

export const CustomCanvas = ({
  spherePosition,
  release,
  setRelease,
  amount = 1,
}) => {
  return release ? (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 9999999,
      }}
    >
      <Canvas>
        <directionalLight position={[0, 0, 5]} intensity={1} />
        <ambientLight />
        <Sphere
          position={spherePosition}
          release={release}
          amount={amount}
          setRelease={setRelease}
        />
      </Canvas>
    </div>
  ) : (
    <></>
  );
};
export default Sphere;
