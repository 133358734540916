import {
  Button,
  Col,
  Dropdown,
  Form,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import { PiShareFatLight } from "react-icons/pi";
import { AiFillHeart } from "react-icons/ai";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import styles from ".././css/Community.module.css";
import { flashAction } from "../../../store/customer/flashSlice";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GoComment } from "react-icons/go";
import ViewIcon from "../../../Components/icon/ViewIcon";
import moment from "moment";
import {
  addComment,
  likeFeedPost,
  unLikeFeedPost,
} from "../../../store/CommunitySlices/FeedSlice";
import {
  followUser,
  unfollowUser,
} from "../../../store/CommunitySlices/SocialMediaSlice";
import { MdDelete, MdEdit, MdMoreVert } from "react-icons/md";
import { sendNotification } from "../../../store/notificationSlice";
import CoinHoverCard from "../../../Components/shimmers/coinHoverCard";
import { createRepost } from "../../../store/CommunitySlices/RepostSlice";
import { BiRepost } from "react-icons/bi";
import DeleteFeedRepostModal from "./Common/DeleteFeedRepostModal";
import ShareFeedRepostModal from "./Common/ShareFeedRepostModal";
import { IoCheckmarkDoneCircleOutline } from "react-icons/io5";
import { updateSpherePosition } from "../../../utils/customer/coinAnimation";
import { CustomCanvas } from "../../../Components/shimmers/CoinAnimation";
import {
  addEarnCoin,
  fetchCoinCount,
  subEarnCoin,
} from "../../../store/customer/EarnCoinSlice";
import { FaEdit, FaTrashAlt } from "react-icons/fa";

const FeedCard = ({
  feed,
  deepDetail = false,
  detail = false,
  repost = false,
  onDelete = () => {},
}) => {
  const [pollData, setPollData] = useState(feed?.poll);
  const loginUser = useSelector((state) => state.auth.user);
  const isSignin = useSelector((state) => state.auth.isSignin);
  const [isLiked, setIsLiked] = useState(feed.isLiked);
  const [likes, setLikes] = useState(feed?.likes?.length);
  const [commentCount, setCommentCount] = useState(feed?.commentCount);
  const [showCommentSection, setShowCommentSection] = useState(false);
  const [comment, setComment] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showAllImages, setShowAllImages] = useState(false);
  const [isFollowing, setIsFollowing] = useState(feed.isFollowing);
  const [posting, setPosting] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRepostModal, setShowRepostModal] = useState(false);
  const [caption, setCaption] = useState("");
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [hoverData, setHoverData] = useState(null);
  const [hoverPosition, setHoverPosition] = useState({ x: 0, y: 0 });
  const [showShare, setShowShare] = useState(false);
  const [release, setRelease] = useState(false);
  const [openOption, setOpenOption] = useState(false);
  const [spherePosition, setSpherePosition] = useState([0, 0, 0]);
  const [amount, setAmount] = useState(1);
  const { amounts } = useSelector((state) => state.earnCoin);
  const fcmToken = feed?.fcmToken;
  const userId = feed?.createdBy?._id;
  const likeRef = useRef();
  const repostref = useRef();
  const commentRef = useRef();
  const followRef = useRef();



  useEffect(() => {
    const handleResize = () => {
      updateSpherePosition(likeRef, setSpherePosition);
      updateSpherePosition(repostref, setSpherePosition);
      updateSpherePosition(commentRef, setSpherePosition);
      updateSpherePosition(followRef, setSpherePosition);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [likeRef, setSpherePosition]);



  const handleRepost = async () => {
    await dispatch(createRepost({ caption, originalPostId: feed?._id })).unwrap;
    setAmount(amounts?.feedRepost);
    updateSpherePosition(repostref, setSpherePosition);
    setRelease(!release);
    setCaption("");
    setTimeout(() => setShowRepostModal(false), 2000);
  };

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
  };

  const handleCloseImageModal = () => {
    setSelectedImageIndex(null);
  };

  const goToNextImage = () => {
    setSelectedImageIndex((prev) => (prev + 1) % feed.images.length);
  };

  const goToPreviousImage = () => {
    setSelectedImageIndex(
      (prev) => (prev - 1 + feed.images.length) % feed.images.length
    );
  };

  const handleFollowToggle = async () => {
    if (!loginUser) {
      setPosting(true);
      dispatch(
        flashAction.addFlash({
          message: "Please log in to Follow.",
          type: "Fail",
        })
      );
      navigate("/signin");
      return;
    }
    try {
      let response;
      if (isFollowing) {
        response = await dispatch(unfollowUser(feed?.createdBy?.userName));
        setAmount(amounts?.follow);
        const action = await dispatch(subEarnCoin(amount));
        if (subEarnCoin.fulfilled.match(action)) {
          dispatch(fetchCoinCount());
        }
        setIsFollowing(!isFollowing);
      } else {
        response = await dispatch(followUser(feed?.createdBy?.userName));
        setAmount(amounts?.follow);
        updateSpherePosition(followRef, setSpherePosition);
        setRelease(!release);
        setIsFollowing(!isFollowing);
        if (userId !== loginUser?._id) {
          dispatch(
            sendNotification({
              title: "Follow",
              message: `New Follower arrived.`,
              token: fcmToken,
              link: `/community/profile/${loginUser?.userName}`,
              userId: userId,
              type: "community",
              repliesBy: loginUser?._id,
            })
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleShowRepostModel = () => setShowRepostModal(true);
  const handleCloseRepostModel = () => setShowRepostModal(false);

  const handleMouseEnter = (id, event) => {
    const scrollX = window.scrollX;
    const scrollY = window.scrollY;

    const xPos = event.clientX + scrollX + 10;
    const yPos = event.clientY + scrollY + 10;

    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    const tooltipWidth = 200;
    const tooltipHeight = 200;

    let adjustedX;
    if (xPos + tooltipWidth > scrollX + screenWidth) {
      adjustedX = scrollX + screenWidth - tooltipWidth - 10;
    } else if (xPos < scrollX) {
      adjustedX = scrollX + 10;
    } else {
      adjustedX = xPos;
    }

    let adjustedY;
    if (yPos + tooltipHeight > scrollY + screenHeight) {
      adjustedY = scrollY + screenHeight - tooltipHeight - 10;
    } else if (yPos < scrollY) {
      adjustedY = scrollY + 10;
    } else {
      adjustedY = yPos;
    }

    setHoverData(id);
    setHoverPosition({ x: adjustedX, y: adjustedY });
  };

  const handleMouseLeave = () => {
    setHoverData(null);
    setHoverPosition({ x: 0, y: 0 });
  };

  const renderPostWithTags = (post) => {
    if (!post) return null;
    let updatedPost = post.replace(/@(\w+)/g, (match, userName) => {
      const user = feed.userTags.find((u) => u.userName === userName);
      return user
        ? `<span class="text-info">
        @<a href="/community/profile/${user.userName}" class="tag-link text-decoration-none text-reset" onclick="event.stopPropagation() ">${user.userName}
        </a>
       </span>`
        : match;
    });
    updatedPost = updatedPost.replace(/\$(\w+)/g, (match, symbol) => {
      const coin = feed.coinTags.find((c) => c.symbol === symbol);
      return coin
        ? `<span class="text-success">$<a href="/coin-profile/${coin.coinId.id}" data-coin-id=${coin.coinId.id} class="tag-link text-reset text-decoration-none coin-hover" onclick="event.stopPropagation()"
>${symbol}</a></span>`
        : match;
    });
    updatedPost = updatedPost.replace(/#(\w+)/g, (match, tag) => {
      const hashTag = feed.hashTags.find((h) => h.tag === tag);
      return hashTag
        ? `<span class="text-primary">#<a href="/community/topic/${hashTag.tag}/tag" class="tag-link text-decoration-none text-reset" onclick="event.stopPropagation()">${tag}</a></span>`
        : match;
    });
    return updatedPost;
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (!loginUser) {
      setPosting(true);
      dispatch(
        flashAction.addFlash({
          message: "Please log in to comment.",
          type: "Fail",
        })
      );
      navigate("/signin");
      return;
    }
    if (!comment.trim()) return;

    try {
      await dispatch(
        addComment({
          feedId: feed?._id,
          content: comment,
          userId: loginUser?._id,
        })
      ).unwrap();
      setAmount(amounts?.feedComment);
      updateSpherePosition(commentRef, setSpherePosition);
      setRelease(!release);
      if (userId !== loginUser?._id) {
        dispatch(
          sendNotification({
            title: "New Comment",
            message: `${loginUser?.name} commented on your post.`,
            token: fcmToken,
            link: `/community/feed/${feed?._id}`,
            userId: userId,
            type: "community",
            repliesBy: loginUser?._id,
          })
        );
      }

      setPosting(false);
      setComment("");
      setCommentCount((prev) => prev + 1);
      setTimeout(() => setShowCommentSection(!showCommentSection), 2000);
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  const handleLike = async () => {
    if (isSignin) {
      try {
        if (isLiked) {
          await dispatch(
            unLikeFeedPost({ feedId: feed?._id, userId: loginUser?._id })
          );
          setAmount(amounts?.feedLike);
          const action = await dispatch(subEarnCoin(amount));
          if (subEarnCoin.fulfilled.match(action)) {
            dispatch(fetchCoinCount());
          }
          setLikes(likes - 1);
          setIsLiked(false);
        } else {
          await dispatch(
            likeFeedPost({ feedId: feed?._id, userId: loginUser?._id })
          );
          setAmount(amounts?.feedLike);
          updateSpherePosition(likeRef, setSpherePosition);
          setLikes(likes + 1);
          setIsLiked(true);
          setRelease(!release);
          if (userId !== loginUser?._id) {
            dispatch(
              sendNotification({
                title: "Post Liked!",
                message: ` ${loginUser?.name} Like Your Post.`,
                token: fcmToken,
                link: `/community/feed/${feed?._id}`,
                image: feed?.images[0]?.url,
                userId: userId,
                type: "community",
                repliesBy: loginUser?._id,
              })
            );
          }
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      dispatch(
        flashAction.addFlash({
          message: "Please log in to like or unlike the post.",
          type: "Fail",
        })
      );
      navigate("/signin");
    }
  };

  const handleToggleDropdown = () => {
    setOpenOption(!openOption);
  };

  return (
    <Row>
      <Col xs={2} lg={1} className="mt-4 pt-3 pe-0 overflow-hidden">
        <Link
          to={`/community/profile/${feed?.createdBy?.userName}`}
          className="text-reset text-decoration-none"
        >
          <Image
            src={feed?.createdBy?.profileImg?.url}
            roundedCircle
            style={{
              width: "100%",
              maxWidth: "50px",
              maxHeight: "50px",
              objectFit: "cover",
            }}
          />
        </Link>
      </Col>
      <Col xs={10} lg={11}>
        <div className={styles.feedFollowingWrapper}>
          <div className={styles.feedProfileWrapper}>
            <div>
              <Link
                to={`/community/profile/${feed?.createdBy?.userName}`}
                className="text-reset text-decoration-none"
              >
                <span className={styles.feedName}>{feed?.createdBy?.name}</span>
              </Link>
              <Link
                to={`/community/profile/${feed?.createdBy?.userName}`}
                className="text-reset text-decoration-none d-lg-none"
              >
                <div className={styles.feedUsername}>
                  @{feed?.createdBy?.userName}
                </div>
              </Link>
              <span className={styles.feedTimestamp}>
                {moment(feed?.createdAt).fromNow()}
              </span>
            </div>
            <Link
              to={`/community/profile/${feed?.createdBy?.userName}`}
              className="text-reset text-decoration-none d-none d-lg-block"
            >
              <div className={styles.feedUsername}>
                @{feed?.createdBy?.userName}
              </div>
            </Link>
          </div>

          {repost ? (
            <></>
          ) : (
            <div className={styles.postButtonWrapper}>
              {feed?.createdBy?.userName === loginUser?.userName ? (
                <>
                  <Dropdown
                    className={styles.commentDropdown}
                    show={openOption}
                    onToggle={handleToggleDropdown}
                  >
                    <MdMoreVert onClick={handleToggleDropdown} />
                    <Dropdown.Menu className={styles?.dropdownMenu}>
                      <Dropdown.Item
                        as={Link}
                        to={`/community/edit/${feed?._id}`}
                        className={`text-reset text-decoration-none ${styles.dropdownIconWrapper}`}
                      >
                        <FaEdit /> Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => setShowDeleteModal(true)}
                        className={styles.dropdownIconWrapper}
                      >
                        <FaTrashAlt /> Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              ) : isFollowing ? (
                <></>
              ) : (
                <Button
                  variant="primary"
                  ref={followRef}
                  className={styles.followButton}
                  onClick={handleFollowToggle}
                >
                  Follow
                </Button>
              )}
            </div>
          )}
        </div>
        <div
          className={styles.feedSentiment}
          style={{
            color: feed?.sentiment === "bullish" ? "green" : "red",
          }}
        >
          {feed?.sentiment
            ? feed?.sentiment.charAt(0).toUpperCase() +
              feed?.sentiment.slice(1).toLowerCase()
            : ""}
        </div>
        <Link
          to={`/community/feed/${feed?._id}`}
          className="text-reset text-decoration-none"
        >
          <p
            dangerouslySetInnerHTML={{
              __html: renderPostWithTags(feed?.post),
            }}
            onMouseOver={(e) => {
              const target = e.target;
              if (target.classList.contains("coin-hover")) {
                const id = target.getAttribute("data-coin-id");
                handleMouseEnter(id, e);
              }
            }}
            onMouseOut={(e) => {
              if (e.target.classList.contains("coin-hover")) {
                handleMouseLeave();
              }
            }}
          />
          {feed?.isAdmin ? (
            <Link to={feed?.newsLink} onClick={(e) => e.stopPropagation()}>
              Read More..
            </Link>
          ) : (
            <></>
          )}
        </Link>
        {hoverData && (
          <CoinHoverCard hoverData={hoverData} hoverPosition={hoverPosition} />
        )}

        <div>
          {feed?.images?.length > 0 && (
            <div className={styles.imageGrid}>
              {feed.images.map((image, index) => (
                <div
                  key={index}
                  style={{ width: "100%" }}
                  className={
                    feed.images.length === 1
                      ? styles.singleImagerapper
                      : styles.gridImageWrapper
                  }
                  onClick={() => handleImageClick(index)}
                >
                  <img
                    src={image.url}
                    alt={`Image ${index + 1}`}
                    className={
                      feed.images.length === 1
                        ? styles.singleImage
                        : styles.gridImage
                    }
                  />
                </div>
              ))}
            </div>
          )}

          {selectedImageIndex !== null && (
            <Modal
              show={selectedImageIndex !== null}
              onHide={handleCloseImageModal}
              centered
              contentClassName={styles.transparentModal}
            >
              <Modal.Body className={styles.modalBody}>
                <button
                  className={styles.prevButton}
                  onClick={goToPreviousImage}
                >
                  &#8249;
                </button>
                <img
                  src={feed.images[selectedImageIndex]?.url}
                  alt={`Enlarged image ${selectedImageIndex + 1}`}
                  className={styles.modalImage}
                />
                <button className={styles.nextButton} onClick={goToNextImage}>
                  &#8250; {/* Right arrow */}
                </button>
              </Modal.Body>
            </Modal>
          )}

          {/* Poll Data Section */}
          {pollData && (
            <Feedpoll
              feed={feed}
              userId={loginUser?._id}
              onClick={(e) => e.stopPropagation()}
            />
          )}
        </div>

        {repost || deepDetail ? (
          <></>
        ) : (
          <div>
            <div className={styles.feedReactionIconGroup}>
              <span className={styles.reactionIconWrapper}>
                <ViewIcon className={styles.feedViewIcon} /> {feed?.views}
              </span>
              <span ref={likeRef} className={styles.reactionIconWrapper}>
                <AiFillHeart
                  onClick={handleLike}
                  size={18}
                  style={{
                    color: isLiked ? "red" : "#999",
                    cursor: "pointer",
                    marginRight: "0.3rem",
                  }}
                />
                {"  "}
                {likes}
              </span>

              <span
                className={styles.reactionIconWrapper}
                onClick={
                  detail ? null : () => setShowCommentSection((prev) => !prev)
                }
              >
                <GoComment className={styles.feedCommentIcon} size={18} />
                {commentCount}
              </span>
              <span
                className={styles.reactionIconWrapper}
                onClick={detail ? null : handleShowRepostModel}
              >
                <BiRepost className={styles.feedCommentIcon} size={22} />
                {feed?.repostCount}
              </span>
              <span
                className={styles.reactionIconWrapper}
                onClick={() => setShowShare(true)}
              >
                <PiShareFatLight className={styles.feedShareICon} size={24} />
              </span>
            </div>
            {showCommentSection && (
              <div className="mt-3">
                <Form
                  onSubmit={handleCommentSubmit}
                  className="d-flex align-items-start"
                >
                  <Image
                    src={loginUser?.profileImg?.url}
                    roundedCircle
                    width={40}
                    height={40}
                    className="me-2"
                  />
                  <Form.Control
                    as="textarea"
                    rows={2}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder="Write your comment..."
                  />

                  <Button
                    type="submit"
                    ref={commentRef}
                    className={styles.postButton}
                    disabled={posting}
                  >
                    {posting ? "Posting..." : "Post"}
                  </Button>
                </Form>
              </div>
            )}
          </div>
        )}
      </Col>

      {/* Modal for Caption */}
      <Modal show={showRepostModal} onHide={handleCloseRepostModel} centered>
        <Modal.Header closeButton>
          <Modal.Title className="fs-5 fw-semibold">Repost</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            className="form-control"
            rows="3"
            placeholder="How do you feel about the markets today ?"
            value={caption}
            onChange={(e) => setCaption(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={handleCloseRepostModel}>
            Cancel
          </Button>
          <Button variant="primary" ref={repostref} onClick={handleRepost}>
            Repost
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for share */}
      <ShareFeedRepostModal
        setShowShare={setShowShare}
        showShare={showShare}
        feed={feed}
      />

      {/* Modal for Delete feed  */}
      <DeleteFeedRepostModal
        onDelete={onDelete}
        setShowDeleteModal={setShowDeleteModal}
        showDeleteModal={showDeleteModal}
        feed={feed}
        detail={detail}
      />

      {release ? (
        <CustomCanvas
          release={release}
          setRelease={setRelease}
          amount={amount}
          spherePosition={spherePosition}
        />
      ) : (
        <></>
      )}
    </Row>
  );
};

const Feedpoll = ({ feed, userId }) => {
  const dispatch = useDispatch();
  const [pollData, setPollData] = useState(feed?.poll || {});
  const isSignin = useSelector((state) => state.auth.isSignin);
  const [hasVoted, setHasVoted] = useState(false);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(null);
  const [isExpired, setIsExpired] = useState(false);
  const [nowVoted,setNowVoted]=useState(0);

  useEffect(() => {
    // Determine if poll has expired
    if (feed?.poll?.expiresAt) {
      const now = new Date();
      setIsExpired(new Date(feed.poll.expiresAt) < now);
    }

    // Check if user has already voted
    if (feed?.poll?.options) {
      feed.poll.options.forEach((option, index) => {
        if (
          option.votedBy.some(
            (voter) => voter.userId?.toString() === userId?.toString()
          )
        ) {
          setHasVoted(true);
          setSelectedOptionIndex(index);
        }
      });
    }
  }, [feed, userId,nowVoted]);

  const handleVote = async (index) => {
    if (hasVoted || isExpired) return; // Disable voting for expired polls or if user already voted
    if (!isSignin) {
      dispatch(
        flashAction.addFlash({
          message: "Please login to vote.",
          type: "Fail",
        })
      );
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/community/feed-poll`,
        {
          feedId: feed?._id,
          optionIndex: index,
          userId,
        }
      );
      setNowVoted(1);
      setSelectedOptionIndex(index);
      setPollData(response.data.poll);
      setHasVoted(true);
    } catch (error) {
      console.error(
        "Error voting:",
        error.response?.data?.message || error.message
      );
    }
  };

  const getTotalVotes = () =>
    pollData?.options?.reduce(
      (total, option) => total + (option?.votedBy?.length || 0),
      0
    ) || 0;

  if (!pollData || pollData?.question === "undefined") {
    return null;
  }
  // if(userId===feed?.createdBy?._id)
  return (
    <div
      className={`${styles.pollContainer} ${
        isExpired ? styles.pollExpired : ""
      }`}
    >
      <h4 className={styles.pollQuestion}>
        {pollData?.question}
        {isExpired && <span className={styles.expiredTag}> (Expired)</span>}
      </h4>
      <div className={styles.pollOptions}>
        {pollData.options.map((option, index) => {
          const totalVotes = getTotalVotes()+nowVoted;
          console.log(totalVotes);
          const extra=selectedOptionIndex === index?nowVoted:0;
          const percentage = totalVotes
            ? Math.round(((option?.votedBy?.length+extra) / totalVotes) * 100)
            : 0;
          return (
            <div
              key={index}
              className={`${
                hasVoted
                  ? selectedOptionIndex === index
                    ? styles.selectedOption
                    : styles.nonSelectedOption
                  : styles.pollOption
              }`}
              onClick={!hasVoted ? () => handleVote(index) : undefined}
              style={{
                cursor: hasVoted ? "default" : "pointer",
                background: hasVoted
                  ? `linear-gradient(
                      to right,
                      ${
                        selectedOptionIndex === index ? "#87CEEB" : "#D8DADC"
                      } ${percentage}%,
                      #EAECEF ${percentage}%
                    )`
                  : "#EAECEF",
              }}
            >
              <div>
                {option.option}{" "}
                {selectedOptionIndex === index ? (
                  <IoCheckmarkDoneCircleOutline />
                ) : (
                  <></>
                )}
              </div>
              <div className={styles.percentageBarContainer}>
                <div
                  className={styles.percentageBar}
                  style={{
                    width: `${percentage}%`,
                    backgroundColor:
                      hasVoted && selectedOptionIndex === index
                        ? "#000"
                        : "#999",
                  }}
                />
              </div>
              {hasVoted && (
                <div className={styles.percentageText}>{percentage}%</div>
              )}
            </div>
          );
        })}
      </div>
      {!isExpired &&(
        <div className={styles.pollResultNote}>
          {getTotalVotes()+nowVoted} votes.
        </div>
      )}
      {isExpired && (
        <div className={styles.pollResultNote}>
          {getTotalVotes()} votes. Final Result
        </div>
      )}
    </div>
  );
};

export default FeedCard;
