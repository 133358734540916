import { PerspectiveCamera, Vector3 } from 'three'; 
 
export const updateSpherePosition = (buttonRef,setSpherePosition) => {
      if (buttonRef.current) {
        const rect = buttonRef.current.getBoundingClientRect();

       
        const x = rect.left + rect.width / 2;
        const y = rect.top + rect.height / 2;

       
        const xNormalized = (x / window.innerWidth) * 2 - 1;
        const yNormalized = -(y / window.innerHeight) * 2 + 1;

       
        const vector = new Vector3(xNormalized, yNormalized, 0);
        const camera = new PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        camera.position.z = 5; 

        vector.unproject(camera);

       
        const direction = vector.sub(camera.position).normalize();

       
        const distance = -camera.position.z / direction.z;
        const newPosition = camera.position.clone().add(direction.multiplyScalar(distance));

      
        setSpherePosition([newPosition.x, newPosition.y, newPosition.z]);
      }
    };

    export const updateBucketPosition = (bucketRef,setBucketPosition) => {
      if (bucketRef.current) {
        const rect = bucketRef.current.getBoundingClientRect();

       
        const x = rect.left + rect.width / 2;
        const y = rect.top + rect.height / 2;

       
        const xNormalized = (x / window.innerWidth) * 2 - 1;
        const yNormalized = -(y / window.innerHeight) * 2 + 1;

       
        const vector = new Vector3(xNormalized, yNormalized, 0);
        const camera = new PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        camera.position.z = 5; 

        vector.unproject(camera);

       
        const direction = vector.sub(camera.position).normalize();

       
        const distance = -camera.position.z / direction.z;
        const newPosition = camera.position.clone().add(direction.multiplyScalar(distance));

      
        setBucketPosition([newPosition.x, newPosition.y, newPosition.z]);
      }
    };