import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  addCommentToRepost,
  fetchRepostById,
  likeRepost,
} from "../../../../store/CommunitySlices/RepostSlice";
import FeedCard from "../FeedCard";
import styles from "../.././css/Community.module.css";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import moment from "moment";
import { MdDelete, MdEdit, MdMoreVert } from "react-icons/md";
import { flashAction } from "../../../../store/customer/flashSlice";
import {
  followUser,
  unfollowUser,
} from "../../../../store/CommunitySlices/SocialMediaSlice";
import {
  fetchToken,
  sendNotification,
} from "../../../../store/notificationSlice";
import ViewIcon from "../../../../Components/icon/ViewIcon";
import { AiFillHeart } from "react-icons/ai";
import { GoComment } from "react-icons/go";
import axios from "axios";
import { PiShareFatLight } from "react-icons/pi";
import {
  FacebookIcon,
  LinkedinIcon,
  RedditIcon,
  TelegramIcon,
  WhatsappIcon,
  XIcon,
} from "react-share";
import { FiLink } from "react-icons/fi";
import DeleteFeedRepostModal from "../Common/DeleteFeedRepostModal";
import ShareFeedRepostModal from "../Common/ShareFeedRepostModal";
import { updateSpherePosition } from "../../../../utils/customer/coinAnimation";
import { CustomCanvas } from "../../../../Components/shimmers/CoinAnimation";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import {
  fetchCoinCount,
  subEarnCoin,
} from "../../../../store/customer/EarnCoinSlice";

const RepostCard = ({ repost, detail = false, onDelete = () => {} }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSignin = useSelector((state) => state.auth.isSignin);
  const [isLiked, setIsLiked] = useState(repost.isLiked);
  const [likes, setLikes] = useState(repost?.likes?.length);
  const [commentCount, setCommentCount] = useState(repost?.commentCount);
  const [comment, setComment] = useState("");
  const [posting, setPosting] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isFollowing, setIsFollowing] = useState(repost.isFollowing);
  const loginUser = useSelector((state) => state.auth.user);
  const fcmToken = repost?.fcmToken;
  const [showCommentSection, setShowCommentSection] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [release, setRelease] = useState(false);
  const [openOption, setOpenOption] = useState(false);
  const [spherePosition, setSpherePosition] = useState([0, 0, 0]);
  const [amount, setAmount] = useState(1);
  const { amounts } = useSelector((state) => state.earnCoin);
  const userId = repost?.repostedBy?._id;
  const likeRef = useRef();
  const commentRef = useRef();
  const followRef = useRef();

  useEffect(() => {
    const handleResize = () => {
      updateSpherePosition(likeRef, setSpherePosition);
      updateSpherePosition(commentRef, setSpherePosition);
      updateSpherePosition(followRef, setSpherePosition);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [likeRef, setSpherePosition]);


  const handleToggleDropdown = () => {
    setOpenOption(!openOption);
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (!loginUser) {
      setPosting(true);
      dispatch(
        flashAction.addFlash({
          message: "Please log in to comment.",
          type: "Fail",
        })
      );
      navigate("/signin");
      return;
    }
    if (!comment.trim()) return;

    try {
      await dispatch(
        addCommentToRepost({
          repostId: repost?._id,
          content: comment,
          userId: loginUser?._id,
        })
      ).unwrap();
      setAmount(amounts?.feedComment);
      updateSpherePosition(commentRef, setSpherePosition);
      setRelease(!release);
      if (userId !== loginUser?._id) {
        dispatch(
          sendNotification({
            title: "New Comment",
            message: `${loginUser?.name} commented on your post.`,
            token: fcmToken,
            link: `/community/repost/${repost?._id}`,
            userId: userId,
            type: "community",
            repliesBy: loginUser?._id,
          })
        );
      }

      setPosting(false);
      setComment("");
      setCommentCount(commentCount + 1);
      setTimeout(() => setShowCommentSection(!showCommentSection), 2000);
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  const handleFollowToggle = async () => {
    if (!loginUser) {
      dispatch(
        flashAction.addFlash({
          message: "Please log in to Follow.",
          type: "Fail",
        })
      );
      navigate("/signin");
      return;
    }

    try {
      if (isFollowing) {
        await dispatch(unfollowUser(repost?.createdBy?.userName));
        setIsFollowing(false);
        setAmount(amounts?.follow);
        const action = await dispatch(subEarnCoin(amount));
        if (subEarnCoin.fulfilled.match(action)) {
          dispatch(fetchCoinCount());
        }
      } else {
        await dispatch(followUser(repost?.createdBy?.userName));
        setAmount(amounts?.follow);
        updateSpherePosition(followRef, setSpherePosition);
        setRelease(!release);
        setIsFollowing(true);

        if (userId !== loginUser?._id) {
          dispatch(
            sendNotification({
              title: "Follow",
              message: `New Follower arrived.`,
              token: fcmToken,
              link: `/community/profile/${loginUser?.userName}`,
              userId: userId,
              type: "community",
              repliesBy: loginUser?._id,
            })
          );
        }
      }
    } catch (err) {
      console.error(err);
    }
  }; 

  const handleLike = async () => {
    if (isSignin) {
      if (!repost) return;
      dispatch(likeRepost(repost?._id));
      if (isLiked) {
        setAmount(amounts?.feedLike);
        const action = await dispatch(subEarnCoin(amount));
        if (subEarnCoin.fulfilled.match(action)) {
          dispatch(fetchCoinCount());
        }
        setLikes(likes - 1);
      } else {
        setLikes(likes + 1);
        setAmount(amounts?.feedLike);
        updateSpherePosition(likeRef, setSpherePosition);
        setRelease(!release);
      }

      setIsLiked(!isLiked);
      if (userId !== loginUser?._id) {
        dispatch(
          sendNotification({
            title: "Post Liked!",
            message: ` ${loginUser?.name} Like Your Post.`,
            token: fcmToken,
            link: `/community/repost/${repost?._id}`,
            image: repost?.originalPost?.images[0]?.url || "",
            userId: userId,
            type: "community",
            repliesBy: loginUser?._id,
          })
        );
      }
    } else {
      dispatch(
        flashAction.addFlash({
          message: "Please log in to like or unlike the post.",
          type: "Fail",
        })
      );
      navigate("/signin");
    }
  };

  const handleDeleteConfirmation = () => setShowDeleteModal(true);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  return (
    <Row>
      <Col xs={2} lg={1} className="mt-4 pt-3 pe-0 overflow-hidden">
        <Link
          to={`/community/profile/${repost?.repostedBy?.userName}`}
          className="text-reset text-decoration-none"
        >
          <Image
            src={repost?.repostedBy?.profileImg?.url}
            roundedCircle
            style={{
              width: "100%",
              maxWidth: "50px",
              maxHeight: "50px",
              objectFit: "cover",
            }}
          />
        </Link>
      </Col>
      <Col xs={10} lg={11}>
        <div className={styles.feedFollowingWrapper}>
          <div className={styles.feedProfileWrapper}>
            <div>
              <Link
                to={`/community/profile/${repost?.repostedBy?.userName}`}
                className="text-reset text-decoration-none"
              >
                <span className={styles.feedName}>
                  {repost?.repostedBy?.name}
                </span>
              </Link>
              <Link
                to={`/community/profile/${repost?.repostedBy?.userName}`}
                className="text-reset text-decoration-none d-lg-none"
              >
                <div className={styles.feedUsername}>
                  @{repost?.repostedBy?.userName}
                </div>
              </Link>
              <span className={styles.feedTimestamp}>
                {moment(repost?.createdAt).fromNow()}
              </span>
            </div>
            <Link
              to={`/community/profile/${repost?.crearepostedBytedBy?.userName}`}
              className="text-reset text-decoration-none d-none d-lg-block"
            >
              <div className={styles.feedUsername}>
                @{repost?.repostedBy?.userName}
              </div>
            </Link>
          </div>

          <div className={styles.postButtonWrapper}>
            {repost?.repostedBy?.userName === loginUser?.userName ? (
              <>
                <Dropdown
                  className={styles.commentDropdown}
                  show={openOption}
                  onToggle={handleToggleDropdown}
                >
                  <MdMoreVert onClick={handleToggleDropdown} />
                  <Dropdown.Menu className={styles?.dropdownMenu}>
                    <Dropdown.Item
                      as={Link}
                      to={`/community/edit/${repost?._id}`}
                      className={`text-reset text-decoration-none ${styles.dropdownIconWrapper}`}
                    >
                      <FaEdit /> Edit
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={handleDeleteConfirmation}
                      className={styles.dropdownIconWrapper}
                    >
                      <FaTrashAlt /> Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            ) : isFollowing ? (
              <></>
            ) : (
              <Button
                variant="primary"
                ref={followRef}
                className={styles.followButton}
                onClick={handleFollowToggle}
              >
                Follow
              </Button>
            )}
          </div>
        </div>

        <Link
          to={`/community/repost/${repost?._id}`}
          className="text-decoration-none text-reset"
        >
          <div>{repost?.caption}</div>
        </Link>
        <FeedCard feed={repost?.originalPost} detail={true} repost={true} />

        <div>
          <div className={styles.feedReactionIconGroup}>
            <span className={styles.reactionIconWrapper}>
              <ViewIcon className={styles.feedViewIcon} /> {repost?.views}
            </span>
            <span ref={likeRef} className={styles.reactionIconWrapper}>
              <AiFillHeart
                onClick={handleLike}
                size={18}
                style={{
                  color: isLiked ? "red" : "#999",
                  cursor: "pointer",
                  marginRight: "0.3rem",
                }}
              />
              {"  "}
              {likes}
            </span>

            <span
              className={styles.reactionIconWrapper}
              onClick={
                detail ? null : () => setShowCommentSection((prev) => !prev)
              }
            >
              <GoComment className={styles.feedCommentIcon} size={18} />
              {commentCount}
            </span>
            <span className={styles.reactionIconWrapper}>
              <PiShareFatLight
                className={styles.feedShareICon}
                onClick={() => setShowShare(true)}
                size={24}
              />
            </span>
          </div>
          {showCommentSection && (
            <div className="mt-3">
              <Form
                onSubmit={handleCommentSubmit}
                className="d-flex align-items-start"
              >
                <Image
                  src={loginUser?.profileImg?.url}
                  roundedCircle
                  width={40}
                  height={40}
                  className="me-2"
                />
                <Form.Control
                  as="textarea"
                  rows={2}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  placeholder="Write your comment..."
                />

                <Button
                  ref={commentRef}
                  type="submit"
                  className={styles.postButton}
                  disabled={posting}
                >
                  {posting ? "Posting..." : "Post"}
                </Button>
              </Form>
            </div>
          )}
        </div>
      </Col>

      {/* Share Modal  */}
      <ShareFeedRepostModal
        setShowShare={setShowShare}
        showShare={showShare}
        repost={repost}
      />

      {/* Delete Modal */}
      <DeleteFeedRepostModal
        onDelete={onDelete}
        setShowDeleteModal={setShowDeleteModal}
        showDeleteModal={showDeleteModal}
        repost={repost}
        detail={detail}
      />

      {release ? (
        <CustomCanvas
          release={release}
          setRelease={setRelease}
          spherePosition={spherePosition}
        />
      ) : (
        <></>
      )}
    </Row>
  );
};

export default RepostCard;
