import React from "react";
const Dashboard = React.lazy(() => import("./views/dashboard/Dashboard"));
const Colors = React.lazy(() => import("./views/theme/colors/Colors"));

const Members = React.lazy(() => import("./views/members/members/Members"));

const NewsAndReports = React.lazy(() =>
  import("./views/news-reports/add-news/AddNews")
);
const ManagePost = React.lazy(() =>
  import("./views/news-reports/manage-post/ManagePost")
);

const MarketNews = React.lazy(() =>
  import("./views/news-reports/market-news/MarketNews")
);
const MarketAnalysis = React.lazy(() =>
  import("./views/news-reports/market-analysis/MarketAnalysis")
);
const MarketReports = React.lazy(() =>
  import("./views/news-reports/market-reports/MarketReports")
);
const Articles = React.lazy(() =>
  import("./views/news-reports/articles/Articles")
);
const Analytics = React.lazy(() =>
  import("./views/news-reports/analytics/Analytics")
);
const EarnCoin = React.lazy(() =>
  import("./views/cryptocurrency/earn-coin/EarnCoin")
);
const CoinList = React.lazy(() =>
  import("./views/cryptocurrency/coinlist/CoinList")
);
const NewListing = React.lazy(() =>
  import("./views/cryptocurrency/new-listing/NewListing")
);

const UpcomingProject = React.lazy(() =>
  import("./views/cryptocurrency/upcoming-project/UpcomingProject")
);
const ManageProject = React.lazy(() =>
  import("./views/cryptocurrency/manage-project/ManageProject")
);

const Influencers = React.lazy(() =>
  import("./views/influencers/add-influencer/AddInfluencer")
);
const ManageInfluencer = React.lazy(() =>
  import("./views/influencers/manage-influencer/ManageInfluencer")
);

const Ads = React.lazy(() => import("./views/ads/createAds/CreateAds"));
const ManageAds = React.lazy(() => import("./views/ads/manage-ads/ManageAds"));

const adminRoutes = [
  { path: "/dashboard", exact: true, name: "Dashboard" },
  { path: "/dashboard", name: "Dashboard", element: Dashboard },
  { path: "/theme", name: "Theme", element: Colors, exact: true },
  { path: "/theme/colors", name: "Colors", element: Colors },
  { path: "/members", name: "Members", element: Members, exact: true },
  { path: "/admin/members", name: "Members", element: Members },

  {
    path: "/news-reports",
    name: "News",
    element: NewsAndReports,
    exact: true,
  },

  {
    path: "/news-reports/add-news",
    name: "Add Post",
    element: NewsAndReports,
  },

  {
    path: "/news-reports/edit/:slug",
    name: "Edit Post",
    element: NewsAndReports,
  },

  {
    path: "/news-reports/manage-post",
    name: "Manage post",
    element: ManagePost,
  },

  {
    path: "/news-reports/market-news",
    name: "Manage Market News",
    element: MarketNews,
  },
  {
    path: "/news-reports/market-analysis",
    name: "Manage Market Analysis",
    element: MarketAnalysis,
  },
  {
    path: "/news-reports/market-reports",
    name: "Manage Market Reports",
    element: MarketReports,
  },
  {
    path: "/news-reports/articles",
    name: "Manage Articles",
    element: Articles,
  },
  {
    path: "/news-reports/analytics",
    name: "Manage Analytics",
    element: Analytics,
  },

  {
    path: "/cryptocurrency",
    name: "Cryptocurrency",
    element: NewListing,
    exact: true,
  },
  {
    path: "/cryptocurrency/earn-coin",
    name: "Earn Coin",
    element: EarnCoin,
  },
  {
    path: "/cryptocurrency/coinList",
    name: "CoinList",
    element: CoinList,
  },
  {
    path: "/cryptocurrency/new-listing",
    name: "New Listing",
    element: NewListing,
  },

  {
    path: "/cryptocurrency/upcoming-project",
    name: "Upcoming Project",
    element: UpcomingProject,
  },
  {
    path: "/cryptocurrency/upcoming-project/edit/:id",
    name: "Edit Project details",
    element: UpcomingProject,
  },
  {
    path: "/cryptocurrency/manage-project",
    name: "Manage Project",
    element: ManageProject,
  },
  {
    path: "/ads",
    name: "Ads",
    element: Ads,
    exact: true,
  },
  {
    path: "/ads/createAds",
    name: " Create Ads",
    element: Ads,
  },

  {
    path: "/ads/edit/:id",
    name: "Edit Ads",
    element: Ads,
  },

  {
    path: "/ads/manage-ads",
    name: "Manage Ads",
    element: ManageAds,
  },

  {
    path: "/influencers",
    name: "Influencers",
    element: Influencers,
    exact: true,
  },
  {
    path: "/influencers/add-influencer",
    name: " Add Influencers",
    element: Influencers,
  },

  {
    path: "/influencers/edit/:id",
    name: "Edit Influencers",
    element: Influencers,
  },

  {
    path: "/influencers/manage-influencer",
    name: "Manage Influencers",
    element: ManageInfluencer,
  },
];

export default adminRoutes;
