import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchEarnCoinData = createAsyncThunk(
  "earnCoin/estimate",
  async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/api/earnCoin/getEarnCoin`
    );
    return response.data;
  }
);

export const addEarnCoin = createAsyncThunk(
  "earnCoin/addCount",
  async (amount, { rejectWithValue }) => {
    try {
      console.log(amount);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/user-profile/addIndexCoinCount`,
        { amount }, // Pass 'amount' in the body if using POST
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data.count;
    } catch (err) {
      return rejectWithValue(
        err.response?.data || "Failed to fetch coin count"
      );
    }
  }
); 

export const subEarnCoin = createAsyncThunk(
  "earnCoin/subCount",
  async (amount, { rejectWithValue }) => {
    try {
      console.log(amount);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/user-profile/subIndexCoinCount`,
        { amount },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data.count;
    } catch (err) {
      return rejectWithValue(
        err.response?.data || "Failed to fetch coin count"
      );
    }
  }
);

export const fetchCoinCount = createAsyncThunk(
  "coin/fetchCount",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/user-profile/getIndexCoinCount`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data.count;
    } catch (err) {
      return rejectWithValue(
        err.response?.data || "Failed to fetch coin count"
      );
    }
  }
);

const coinSlice = createSlice({
  name: "earnCoin",
  initialState: {
    count: 0,
    amounts: null,
    amountStatus: "idle",
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCoinCount.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchCoinCount.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.count = action.payload;
      })
      .addCase(fetchCoinCount.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      .addCase(fetchEarnCoinData.pending, (state) => {
        state.amountStatus = "loading";
      })
      .addCase(fetchEarnCoinData.fulfilled, (state, action) => {
        state.amountStatus = "succeeded";
        state.amounts = action.payload;
      })
      .addCase(fetchEarnCoinData.rejected, (state, action) => {
        state.amountStatus = "failed";
        state.error = action.error.message;
      });
  },
});
export default coinSlice.reducer;
