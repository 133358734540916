import React from "react";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import indexByteStore from "./store/store";
import { router } from "./router";

import AdminApp from "./admin/AdminApp";
import adminRoutes from "./admin/adminRoutes";

const isAdminRoute = window.location.pathname.startsWith("/admin");
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <StrictMode>
  <Provider store={indexByteStore}>
    {isAdminRoute ? (
      <AdminApp route={adminRoutes} />
    ) : (
      <RouterProvider router={router} />
    )}
  </Provider>
  // </StrictMode>
);

reportWebVitals();
