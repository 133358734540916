import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Async Thunks for interacting with the API

// 0. Get Repost by Id
export const fetchRepostById = createAsyncThunk(
  "repost/fetchById",
  async (repostId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/community/getRepostById/${repostId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          }
        }
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// 1. Create Repost
export const createRepost = createAsyncThunk(
  "reposts/createRepost",
  async (repostData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/community/createRepost`,
        repostData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(response);
      return response.data; // Return successful response
    } catch (error) {
      return rejectWithValue(error.response.data); // Handle errors
    }
  }
);

// 2. Get Reposts by Feed
export const getRepostsByFeed = createAsyncThunk(
  "reposts/getRepostsByFeed",
  async (originalPostId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/community/getRepostsByFeed/${originalPostId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          }
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// 3. Like Repost
export const likeRepost = createAsyncThunk(
  "reposts/likeRepost",
  async (repostId, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/community/likeRepost/${repostId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// 4. Add Comment to Repost
export const addCommentToRepost = createAsyncThunk(
  "reposts/addCommentToRepost",
  async (commentData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/community/addCommentToRepost/${commentData.repostId}`,
        { content: commentData.content },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// 5. Get Viral Reposts
export const getViralReposts = createAsyncThunk(
  "reposts/getViralReposts",
  async ({ page, limit }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/community/getViralReposts`,
        {
          params: { page, limit },

          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          }
        }
      );
      return response.data; // Ensure the data is in the correct format
    } catch (error) {
      return rejectWithValue(error.response?.data || "Server Error");
    }
  }
);

const repostSlice = createSlice({
  name: "reposts",
  initialState: {
    reposts: [],
    repost: null,
    viralReposts: [], // Store viral reposts here
    repostPagination: {
      totalFeeds: 0,
      currentPage: 1,
      totalPages: 0,
    },
    isLoading: false,
    isLiked: false,
    isCreate: false,
    isGet: false,
    isComment: false,
    isViralFetch: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // 0. Get Repost by Id
      .addCase(fetchRepostById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchRepostById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.repost = action.payload; // Store the repost data
      })
      .addCase(fetchRepostById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      // 1. Create Repost
      .addCase(createRepost.pending, (state) => {
        state.isCreate = true;
      })
      .addCase(createRepost.fulfilled, (state, action) => {
        state.isCreate = false;
        state.reposts.push(action.payload.data);
      })
      .addCase(createRepost.rejected, (state, action) => {
        state.isCreate = false;
        state.error = action.payload;
      })
      // 2. Get Reposts by Feed
      .addCase(getRepostsByFeed.pending, (state) => {
        state.isGet = true;
      })
      .addCase(getRepostsByFeed.fulfilled, (state, action) => {
        state.isGet = false;
        state.reposts = action.payload.data;
      })
      .addCase(getRepostsByFeed.rejected, (state, action) => {
        state.isGet = false;
        state.error = action.payload;
      })
      // 3. Like Repost
      .addCase(likeRepost.pending, (state) => {
        state.isLiked = true;
      })
      .addCase(likeRepost.fulfilled, (state, action) => {
        state.isLiked = false;
        const index = state.reposts.findIndex(
          (repost) => repost._id === action.payload.data._id
        );
        if (index !== -1) {
          state.reposts[index] = action.payload.data;
        }
      })
      .addCase(likeRepost.rejected, (state, action) => {
        state.isLiked = false;
        state.error = action.payload;
      })
      // 4. Add Comment to Repost
      .addCase(addCommentToRepost.pending, (state) => {
        state.isComment = true;
      })
      .addCase(addCommentToRepost.fulfilled, (state, action) => {
        state.isComment = false;
        const index = state.reposts.findIndex(
          (repost) => repost._id === action.payload.data.repostId
        );
        if (index !== -1) {
          state.reposts[index].commentCount += 1;
        }
      })
      .addCase(addCommentToRepost.rejected, (state, action) => {
        state.isComment = false;
        state.error = action.payload;
      })
      // 5. Get Viral Reposts
      .addCase(getViralReposts.pending, (state) => {
        state.isViralFetch = true;
      })
      .addCase(getViralReposts.fulfilled, (state, action) => {
        state.isViralFetch = false;
        state.status = "succeeded";

        // Ensure that action.payload.reposts is an array
        const reposts = Array.isArray(action.payload.updatedReposts)
          ? action.payload.updatedReposts
          : [];

        // Append the new viral reposts to the existing ones
        state.viralReposts = [...state.viralReposts, ...reposts];
        state.repostPagination = action.payload.pagination;
      })
      .addCase(getViralReposts.rejected, (state, action) => {
        state.isViralFetch = false;
        state.error = action.payload;
      });
  },
});

export default repostSlice.reducer;
