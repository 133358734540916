import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { addCommentToRepost, fetchRepostById } from "../../../../store/CommunitySlices/RepostSlice";
import RepostCard from "./RepostCard";
import styles from "../../css/Community.module.css";
import { flashAction } from "../../../../store/customer/flashSlice";
import { sendNotification } from "../../../../store/notificationSlice";
import FeedCommentCard from "../feed/FeedCommentCard";
import { Button, Container, Form, Image } from "react-bootstrap";
import { fetchComments } from "../../../../store/CommunitySlices/FeedSlice";
import { GoArrowLeft } from "react-icons/go";
import { updateSpherePosition } from "../../../../utils/customer/coinAnimation";
import { CustomCanvas } from "../../../../Components/shimmers/CoinAnimation";


const RepostDetail = ({ }) => {
  const {id}=useParams()
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { repost, isLoading, error } = useSelector((state) => state.reposts);
  const loginUser = useSelector((state) => state.auth.user);
  const [comment, setComment] = useState("");
  const [posting, setPosting] = useState(false);
  const [release, setRelease] = useState(false);
    const [commented,setCommented]=useState(false);
        const [spherePosition, setSpherePosition] = useState([0, 0, 0]);
        const [amount,setAmount]=useState(1);
            const {amounts}=useSelector((state) => state.earnCoin);
      
  const { comments, status } = useSelector((state) => state.feed);
  const fcmToken = repost?.fcmToken;
  const commentRef=useRef();

 
  useEffect(() => {
    dispatch(fetchRepostById(id));
    dispatch(fetchComments({id, type:"repost"}));
  }, [dispatch, id]);

  
useEffect(() => {
          const handleResize = () => {
            updateSpherePosition(commentRef, setSpherePosition);
          };
        
          window.addEventListener("resize", handleResize);
        
          return () => {
            window.removeEventListener("resize", handleResize);
          };
        }, [commentRef, setSpherePosition]);


    const handleGoBack = () => {
        navigate("/community");
      };

      const handleCommentSubmit=async(e)=>{
           e.preventDefault();
              if (!loginUser) {
                setPosting(true);
                dispatch(
                  flashAction.addFlash({
                    message: "Please log in to comment.",
                    type: "Fail",
                  })
                );
                navigate("/signin");
                return;
              }
              if (!comment.trim()) return;
          
              try {
                await dispatch(
                  addCommentToRepost({
                    repostId: repost?._id,
                    content: comment,
                    userId: loginUser?._id,
                  })
                ).unwrap();
                dispatch(
                  sendNotification({
                    title: "New Comment",
                    message: `${loginUser?.name} commented on your post.`,
                    token: fcmToken,
                    link: `/community/feed/${repost?._id}`,
                    userId: loginUser?._id,
                    type: "community",
                  })
                );
                 setAmount(amounts?.feedComment)
                updateSpherePosition(commentRef, setSpherePosition);
                                setRelease(!release);
                setPosting(false);
                setComment("");
              } catch (error) {
                console.error("Error adding comment:", error);
              }
        }

 

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <Container fluid>
    <div className={styles?.postDetailLeftWrapper}>
            <GoArrowLeft
              size={24}
              onClick={handleGoBack}
              style={{ cursor: "pointer" }}
            />
            <h4> Post Details</h4>
          </div>
    <RepostCard repost={repost} detail={true} />
    <hr className="py-1 mb-3" />

    <div className="mt-3">
        <Form
          onSubmit={handleCommentSubmit}
          className="d-flex align-items-start"
        >
          <Image
            src={loginUser?.profileImg?.url}
            roundedCircle
            width={40}
            height={40}
            className="me-2"
          />
          <Form.Control
            as="textarea"
            rows={2}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Write your comment..."
          />
          <Button
            type="submit"
            ref={commentRef}
            className={styles.commentButton}
            disabled={posting}
          >
            {posting ? "Posting..." : "Reply"}
          </Button>
        </Form>
      </div>

    <div className={styles.tabContent}>
          {comments?.map((comment) => (
            <>
              <FeedCommentCard comment={comment} />
              <div className={styles.separator}></div>
            </>
          ))}
        </div>
         {release?  <CustomCanvas release={release} amount={amount} setRelease={setRelease} spherePosition={spherePosition}/>:<></>}
    </Container>

    // <div className={styles.repostContainer}>
    //   <div className={styles.orignalFeed}>
    //     
    //   </div>
    // </div>
    
  );
};

export default RepostDetail;